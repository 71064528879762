import React, {useState, useEffect} from 'react';
import {Helmet} from "react-helmet";

// Style
import './demo.scss';

import logo from '../images/logoDemo.png';
import and from '../images/and.png';
import data from '../data.js';

import demoVideo from '../videos/demo.mp4'

// markup
const Demo = ({location}) => {
  const [name, setName] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get("id");
    const index = data.findIndex(e => e.id === id);
    if(data && data[index]) {
      setName(data[index].name);
    }
  }, [location, data]);

  return (
    <main className={"demoContainer"}>
      <Helmet title={"Demo"}>
        <html lang="fr" />
        <meta charSet="utf-8" />        
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-63J6THBWF0"></script>
        <script dangerouslySetInnerHTML={{__html:`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-63J6THBWF0');`}}>
          
        </script>
      </Helmet>
      <div className="titleContainer">
        <img className={'logo'} src={logo} alt="logo" />
        <img className={'and'} src={and} alt="and" />
        <h1>{name}</h1>
      </div>
      <div className="video">
        <video controls onPlay={"gtag('event', 'click', {'event_category': 'button', 'event_label': 'vidéo lancée'});"} onEnded={"gtag('event', 'click', {'event_category': 'button', 'event_label': 'vidéo finie'});"}>
          <source src={demoVideo}
                  type="video/mp4" />
        </video>
      </div>
      <div className="description">
        <p>Prenons rendez-vous pour en parler !</p>
      </div>
      <a href="https://calendly.com/gderobien/15min" target='_blank'>
        <button>
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.9814 4.19043H5.98145C4.87688 4.19043 3.98145 5.08586 3.98145 6.19043V20.1904C3.98145 21.295 4.87688 22.1904 5.98145 22.1904H19.9814C21.086 22.1904 21.9814 21.295 21.9814 20.1904V6.19043C21.9814 5.08586 21.086 4.19043 19.9814 4.19043Z" stroke="#FDFDFD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.9814 2.19043V6.19043" stroke="#FDFDFD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.98145 2.19043V6.19043" stroke="#FDFDFD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.98145 10.1904H21.9814" stroke="#FDFDFD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Calendly
        </button>
      </a>
    </main>
  )
}

export default Demo

